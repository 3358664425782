import makeRequest, { makeParams, RequestMethod } from "../make.request";
import urls from "../urls";

export default class ExamService {
    static async all(search?:any){
         const params = makeParams([
      {
        index: "search",
        value: search,
      },
    ]);
        return await makeRequest(urls.private.exam.all + params, RequestMethod.GET)
    }
    static async update(payload:any,id:string){
       
        return await makeRequest(urls.private.exam.update + id , RequestMethod.PUT, payload)
    }

    static async create(payload:any){
        return await makeRequest(urls.private.exam.create  , RequestMethod.POST, payload)
    }

    static async getExams(pageNumber: number, pageSize: number, search?: string) {

    const params = makeParams([
        {
            index: "pageNumber",
            value: pageNumber,
        },
        {
            index: "pageSize",
            value: pageSize,
        },
        {
            index: "search",
            value: search,
        },
    ])

        return await makeRequest(urls.private.exam.all + params , RequestMethod.GET)
    }
}