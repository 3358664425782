import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import { FaNetworkWired, FaUser, FaUserCircle } from "react-icons/fa";

export default function Topbar() {
  const [navigator, setNavigator] = useState<any[]>([]);

  const navigate = useNavigate();


  const logout = () => {
    localStorage.removeItem("authkey-super");
    navigate('/logout')
  };

  return (
    <Navbar bg="white" variant="light" expand="md" className="border border-bottom-1 border-muted">
      <Container fluid>
        <div className="ms-2">
          <Navbar.Brand href="/" className="d-flex align-items-center">
            <FaNetworkWired className="text-muted" />
            <span className="fw-bold ms-2">
              EXMCDN
            </span>
          </Navbar.Brand>
        </div>
        <div>
          <Nav className="mr-auto">
            {navigator.length > 0 &&
              navigator.map((data: any) => {
                return (
                  <Nav.Link key={data.id} href={data.baseurl + "/" + data.route}>
                    {data.name}
                  </Nav.Link>
                );
              })}
          </Nav>
          <NavDropdown
            align="end"
            title={
              <FaUserCircle />
            }
          >
            <NavDropdown.Header>
              <div className="block text-sm">Admin User</div>
              <span className="block truncate text-sm font-medium">
                admin@exmcdn.enixm
              </span>
            </NavDropdown.Header>
            <NavDropdown.Item>Settings</NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={logout}>Sign out</NavDropdown.Item>
          </NavDropdown>
        </div>
      </Container>
    </Navbar>
  );
}
