import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap"
import AuthService from "../../services/public/auth.service";
import Select from 'react-select'
import FileService from "../../services/file.service";
import { toast } from "react-hot-toast";
import CdnService from "../../services/public/cdn.service";

interface IAddCdnAppModal {
    show: any,
    setShow: any
}

export default function AddCdnAppModal(props: IAddCdnAppModal) {

    const [packageData, setPackageData] = useState<any>();
    const [osData, setOsData] = useState<any>();
    const [data, setData] = useState<any>({})


    const getEnums = async () => {
        await AuthService.getEnums().then((res) => {
            if (res.status === 200) {
                setPackageData(res?.data?.ExamEnum?.PackageType?.map((data: any) => {
                    return {
                        label: data.label,
                        value: data.value
                    }
                }))
                setOsData(res?.data?.DevicesEnum?.OSType?.map((data: any) => {
                    return {
                        label: data.label,
                        value: data.value
                    }
                }))
            }
        })
    }

    const uploadFile = async (e: any) => {
        const files = e.target.files
        const form = new FormData()
        form.append('files', files[0])

        await FileService.uploadFile(form).then((res) => {
            if (res.status === 200) {
                setData({ ...data, s3Url: res.data[0]._id, s3AppName: res.data[0].name })
                toast.success("File Uploaded")
            }
        }).catch(e => {
            toast.error('Error while uploading file', e)
        })
    }


    const handleCreateCdnApp = async () => {
        await CdnService.create(data).then((res) => {
            if (res.status === 200) {
                props.setShow(false)
            }
        })
    }


    useEffect(() => {
        getEnums();
    }, [])



    return (
        <Modal show={props.show} onHide={() => props.setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Add CDN App</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Form.Group controlId="model" className="mb-2">
                    <Form.Label>OS Type</Form.Label>
                    <Select
                        options={osData}
                        onChange={(e: any) => setData({ ...data, osType: e.value })}

                    />
                </Form.Group>

                <Form.Group controlId="model" className="mb-2">
                    <Form.Label>Package Type</Form.Label>
                    <Select
                        options={packageData}
                        onChange={(e: any) => setData({ ...data, packageType: e.value })}
                    />
                </Form.Group>
                <Form.Group controlId="model" className="mb-2">
                    <Form.Label>Upload Package</Form.Label>
                    <Form.Control
                        type="file"
                        name="s3Url"
                        onChange={(e) => uploadFile(e)}
                    />
                </Form.Group>

                <div className="d-flex justify-content-between align-items-center mt-4">
                    <Button variant="primary" type="submit" onClick={handleCreateCdnApp}>
                        Save
                    </Button>
                    <Button variant="secondary" onClick={() => props.setShow(false)}>
                        Cancel
                    </Button>
                </div>
            </Modal.Body>


        </Modal>
    )
}