import makeRequest, { makeParams, RequestMethod } from "../make.request";
import urls from "../urls";

export default class NixExmVersionService {
    static async all(search?:any){
        return await makeRequest(urls.private.nixExmVersion.all, RequestMethod.GET)
    }

    static async wallAll(search?:any){
        return await makeRequest(urls.private.nixExmVersion.wallAll, RequestMethod.GET)
    }

     static async update(payload:any,id:string){
       
        return await makeRequest(urls.private.nixExmVersion.update + id , RequestMethod.PUT, payload)
    }

    static async create(payload:any){
        return await makeRequest(urls.private.nixExmVersion.create  , RequestMethod.POST, payload)
    }

    static async createSte(payload: any) {
        return await makeRequest(urls.private.nixExmVersion.createSte, RequestMethod.POST, payload)
    }

    static async createWall(payload: any) {
        return await makeRequest(urls.private.nixExmVersion.createWall, RequestMethod.POST, payload)
    }

    static async deleteSte(steId: any) {
        return await makeRequest(urls.private.nixExmVersion.deleteSte + "/" + steId, RequestMethod.DELETE)
    }

    static async deleteWall(wallId: any) {
        return await makeRequest(urls.private.nixExmVersion.deleteWall + "/" + wallId, RequestMethod.DELETE)
    }

    static async updateSte(steId: any, payload: any) {
        return await makeRequest(urls.private.nixExmVersion.updateSte + "/" + steId, RequestMethod.PUT, payload)
    }

    static async updateWall(wallId: any, payload: any) {
        return await makeRequest(urls.private.nixExmVersion.updateWall + "/" + wallId, RequestMethod.PUT, payload)
    }
}