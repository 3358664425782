import makeRequest, { RequestMethod } from "../make.request";
import urls from "../urls";

export default class AuthService {
    static async login(email: string, password: string){
        return await makeRequest(urls.public.auth.login, RequestMethod.POST, { email, password })
    }

    static async getEnums(){
        return await makeRequest(urls.public.getEnums, RequestMethod.GET)
    }
}