import IRouter from "../interfaces/IRouter";
import { AiFillBook, AiFillDashboard } from "react-icons/ai";
import dashboardNavigator from "../admin/dashboard.navigator";
import Devices from "../views/Admin/Dashboard/Devices";
import Exam from "../views/Admin/Dashboard/Exam";
import { HiOutlineDocumentMagnifyingGlass, HiServerStack, HiVideoCamera } from "react-icons/hi2";
import { BsMenuAppFill } from "react-icons/bs"
import Mapping from "../views/Admin/Dashboard/Mapping";
import Landscape from "../views/Admin/Dashboard/Landscape";
import NixExam from "../views/Admin/Dashboard/NixExam";
import LiveSessions from "../views/Admin/Dashboard/LiveSessions";
import NixWall from "../views/Admin/Dashboard/NixWall";
import CurrentMappings from "../views/Admin/Dashboard/CurrentMappings";
import CdnApp from "../views/Admin/Dashboard/CdnApp/CdnApp";
import CdnAppById from "../views/Admin/Dashboard/CdnApp/CdnAppById";


const adminRoutes: IRouter[] = [
  {
    path: "dashboard",
    element: <Devices />,
    navbarShow: true,
    name: "Devices",
    icon: AiFillDashboard,
    navigator: dashboardNavigator,
  },

  {
    path: "nixExm",
    element: <NixExam />,
    navbarShow: true,
    name: "EXMSTE",
    icon: HiServerStack,
  },

  {
    path: "exmwall",
    element: <NixWall />,
    navbarShow: true,
    name: "EXMWALL",
    icon: HiServerStack,
  },

  {
    path: "landscape",
    element: <Landscape />,
    navbarShow: true,
    name: "Landscape",
    icon: HiServerStack,

  },

  {
    path: "exam",
    element: <Exam />,
    navbarShow: true,
    name: "Exam",
    icon: AiFillBook,
  },
  {
    path: "mapping",
    element: <Mapping />,
    navbarShow: true,
    name: "Mapping",
    icon: HiOutlineDocumentMagnifyingGlass,
  },
  {
    path: "current-mappings",
    element: <CurrentMappings/>,
    navbarShow: true,
    name: "Current Mappings",
    icon: HiOutlineDocumentMagnifyingGlass,
  },
  {
    path: "live-sessions",
    element: <LiveSessions />,
    navbarShow: true,
    name: "Live Sessions",
    icon: HiVideoCamera,
  },
  {
    path: "cdn-app",
    element: <CdnApp />,
    navbarShow: true,
    name: "CDN App",
    icon: BsMenuAppFill,
  },

];

export default adminRoutes;
