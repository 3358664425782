import makeRequest, { makeParams, RequestMethod } from "../make.request";
import urls from "../urls";

export default class MappingService {
    static async all(search?:any){
         const params = makeParams([
      {
        index: "search",
        value: search,
      },
    ]);
        return await makeRequest(urls.private.nixExmVersion.all + params, RequestMethod.GET)
    }
     static async update(payload:any,id:string){
       
        return await makeRequest(urls.private.nixExmVersion.update + id , RequestMethod.PUT, payload)
    }

    static async create(payload:any){
       
        return await makeRequest(urls.private.nixExmVersion.create  , RequestMethod.POST, payload)
    }

    static async createExamMapping(payload: any) {
        return await makeRequest(urls.private.mapping.create, RequestMethod.POST, payload)
    }

    static async getAllExamMapping() {
        return await makeRequest(urls.private.mapping.all, RequestMethod.GET)
    }
}