import { useState } from "react";
import { Button, Form, InputGroup, Table } from "react-bootstrap";
import { AiFillEdit } from "react-icons/ai";
import moment from "moment";
import NixExmVersionService from "../../services/public/nixexamVersion.service";
import { toast } from "react-hot-toast";
import EditNixWallModal from "../Model/EditNixWallVersion";



interface ILiveUserTable {
  tableData: any,
  reload?: any
}

const NixWallTable = ({ tableData, reload }: ILiveUserTable) => {
  const [index, setIndex] = useState<number>(-1);

  const handleWallDelete = async (id: any) => {
    await NixExmVersionService.deleteWall(id).then((res) => {
      if (res.status === 200) {
        reload();
        toast.success("Ste deleted successfully")
      }
    })
  }

  return <>
    <Table hover responsive>
      <thead>
        <tr>
          <th>Sr.No</th>
          <th>App Hash</th>
          <th>Docker Version</th>
          <th>Node Version</th>
          <th>Platform</th>
          <th>Version Date</th>
          <th>File</th>
          <th>Actions</th>

        </tr>
      </thead>
      <tbody className="divide-y">
        {tableData && tableData.length > 0 ? tableData.map((data: any, index: number) => {
          return (
            <tr className="table-light border border-gray-700 bg-white bg-dark" key={index}>
              <td className="nowrap font-weight-medium text-gray-900 text-white">
                <span className="d-flex align-items-center text-dark">
                  {index + 1}
                </span>
              </td>
              <td>{data && data.appHash}</td>
              <td>{data && data.dockerVersion}</td>
              <td>{data && data.nodeVersion}</td>
              <td>{data && data.platform}</td>
              <td>{data && moment(data.versionDate).format("DD MMM YYYY")}</td>
              <td><a href={data?.s3AppName} download className="text-decoration-none" style={{ cursor: "pointer" }}>Download</a> </td>
              <td>
                <Button size="sm" variant="secondary" onClick={() => setIndex(index)} >Edit</Button>
                <Button size="sm" variant="danger" className="ms-2" onClick={() => handleWallDelete(data?._id)}>Delete</Button>
              </td>
            </tr>
          )
        }) : <tr><td colSpan={12}>No data found</td></tr>}
      </tbody>
    </Table>

    <EditNixWallModal
      show={index >= 0 ? true : false}
      handleClose={() => {
        setIndex(-1)
        reload()
      }}
      data={tableData && tableData[index]} />
  </>
}

export default NixWallTable