import React from "react";
import { Routes, Route, Navigate } from "react-router";
import AdminHome from "./home/Admin.home";
import IRouter from "./interfaces/IRouter";
import adminRoutes from "./router/admin.router";
import LoginIndex from "./views/Login/Login.index";
import MainLoader from "./components/Loader/MainLoader";



export default function MainRouter() {
  return (
    <>
      <MainLoader/>
      <Routes>
        {/*path is dashboard the render privateRounter */}
        <Route path="/admin" element={<AdminHome />}>
          {adminRoutes.map((data: IRouter) => {
            // data will follow property of IRouter
            return <Route path={data.path + "/*"} element={data.element} />;
          })}
        </Route>

        {/* if path is login go for login*/}
        <Route path="/login" element={<LoginIndex />} />

        {/* if path is * then go for /login using navigate component */}
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </>
  );
}
