import makeRequest, { makeParams, RequestMethod } from "../make.request";
import urls from "../urls";

export default class DevicesService {
    static async all(search?:any){
         const params = makeParams([
      {
        index: "search",
        value: search,
      },
    ]);
        return await makeRequest(urls.private.devices.all + params, RequestMethod.GET)
    }
    static async update(payload:any,id:string){
       
        return await makeRequest(urls.private.devices.update + id , RequestMethod.PUT, payload)
    }

    static async create(payload:any){
       
        return await makeRequest(urls.private.devices.create  , RequestMethod.POST, payload)
    }
    static async getDeviceDataById(id:string){
                                                           
        return await makeRequest(urls.private.devices.byId + id  , RequestMethod.GET)
    }   
    static async deleteDeviceById(id:string){
                                                           
        return await makeRequest(urls.private.devices.delete+ id  , RequestMethod.DELETE)
    }   

}