import React, { useState } from 'react';
import { OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { HiEye, HiPlusCircle } from 'react-icons/hi2';

interface IMappingTable {
  tableData: any[];
}

const MappingTable = ({ tableData }: IMappingTable) => {
  const [tooltipVisible, setTooltipVisible] = useState(new Array(tableData.length).fill(false));
  const [tooltipContent, setTooltipContent] = useState('');

  const handleEyeClick = (deviceId: any, rowIndex: any) => {
    setTooltipContent(deviceId.map((device: { macId: any; }) => device.macId).join(', '));
    const newTooltipVisible = new Array(tableData.length).fill(false);
    newTooltipVisible[rowIndex] = !newTooltipVisible[rowIndex];
    setTooltipVisible(newTooltipVisible);
  };
  return (
    <Table hover responsive>
      <thead>
        <tr>
          <th>Sr. No</th>
          <th>EXAM</th>
          <th>EXAM STE</th>
          <th>EXAM WALL</th>
          <th>Total Devices</th>
        </tr>
      </thead>
      <tbody className="divide-y">
        {tableData && tableData.length > 0 ?
          tableData.map((data: any, index: number) => {
            return (
              <tr className="table-light border border-gray-700 bg-white bg-dark" key={index}>
                <td className="nowrap font-weight-medium text-gray-900 text-white">
                  <span className="d-flex align-items-center text-dark">
                    {index + 1}
                  </span>
                </td>
                <td>{data.appType ? data.appType : '--'}</td>
                <td>{data.steApp?.appHash ? data.steApp.appHash : '--'}</td>
                <td>{data.wallApp?.appHash ? data.wallApp.appHash : '--'}</td>
                <td>
                  {
                    data.deviceId.length ?
                      <>
                        {data.deviceId.length}
                        <HiEye className="ms-2" onClick={() => { handleEyeClick(data.deviceId, index) }} />
                        {tooltipVisible[index] && (
                          <p>
                            {tooltipContent}
                          </p>
                        )}
                      </>
                      : '--'
                  }
                </td>
              </tr>
            )
          }) : <tr><td colSpan={12}>No data found</td></tr>}
      </tbody>
    </Table>
  );
};

export default MappingTable;
