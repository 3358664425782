import makeRequest, { makeParams, RequestMethod } from "../make.request";
import urls from "../urls";

export default class LandscapeVersionService {
    static async all(search?:any){
         const params = makeParams([
      {
        index: "search",
        value: search,
      },
    ]);
        return await makeRequest(urls.private.versions.all + params, RequestMethod.GET)
    }

    static async create(formData:any){
     return await makeRequest(urls.private.versions.create, RequestMethod.POST, formData)
    }

    static async update(formData:any, id: any){
      return await makeRequest(urls.private.versions.update +"/"+id, RequestMethod.PUT, formData)
     }
}