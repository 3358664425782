import moment from "moment";
import { useState } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";
import DevicesService from "../../services/public/devices.service";
import LandscapeVersionService from "../../services/public/landscapeVersion.service copy";

interface FormModel {
  versionDate: Date;
  last_commit_id: string;
  created_by: string;
  platform: string;
  hash: string;
  pyarmor_version: string;
  pyarmor_key: string;
  python_version: string;
  node_version: string;

}



const AddLandscapeVersionModal = (props: any) => {
  const initialFormData: FormModel = {
    versionDate: new Date(),
    last_commit_id: '',
    created_by: '',
    platform: '',
    hash: '',
    pyarmor_version: '',
    pyarmor_key: '',
    python_version: '',
    node_version: '',
  };

  const [formData, setFormData] = useState<FormModel>(initialFormData);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    // event.preventDefault();
    await LandscapeVersionService.create(formData).then((res) => {
      if (res.status === 200 ) {
        props.setShow(false);
        window.location.reload();
      }
    }).catch((err) => {
      toast.error('Error while creating landscaping version:', err);
    });
  };

  const handleCancel = () => {
    props.setShow(false)
  }
  return (
    <Modal show={props.show} onHide={() => props.setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>ADD</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <Form onSubmit={handleSubmit}> */}
          <div className="mb-4">
            <Form.Group controlId="macId">
              <Form.Label>Version Date</Form.Label>

              <Form.Control
                type="Date"
                name="versionDate"
                value={moment(formData?.versionDate).format('YYYY-MM-DD')}
                onChange={handleChange}
              />
            </Form.Group>
          </div>
          <div className="mb-4">
          </div>
          <Form.Group controlId="model">
            <Form.Label>Last Commit Id</Form.Label>
            <Form.Control
              type="text"
              name="last_commit_id"
              value={formData.last_commit_id}
              onChange={handleChange}
            />
          </Form.Group>
          <div className="mb-4">
          </div>
          <Form.Group controlId="hardwareId">
            <Form.Label>Created By</Form.Label>
            <Form.Control
              type="text"
              name="created_by"
              value={formData.created_by}
              onChange={handleChange}
            />
          </Form.Group>
          <div className="mb-4">
          </div>
          <Form.Group controlId="serialNumber">
            <Form.Label>Platform</Form.Label>
            <Form.Control
              type="text"
              name="platform"
              value={formData.platform}
              onChange={handleChange}
            />
          </Form.Group>
          <div className="mb-4">
          </div>
          <Form.Group controlId="purchaseDate">
            <Form.Label>Hash</Form.Label>
            <Form.Control
              type="text"
              name="hash"
              value={formData.hash}
              onChange={handleChange}
            />
          </Form.Group>
          <div className="mb-4">
          </div>
          <Form.Group controlId="screenSize">
            <Form.Label>Pyarmor version</Form.Label>
            <Form.Control
              type="text"
              name="pyarmor_version"
              value={formData.pyarmor_version}
              onChange={handleChange}
            />
          </Form.Group>
          <div className="mb-4">
          </div>
          <Form.Group controlId="color">
            <Form.Label>Pyarmor Key</Form.Label>
            <Form.Control
              type="text"
              name="pyarmor_key"
              value={formData.pyarmor_key}
              onChange={handleChange}
            />
          </Form.Group>
          <div className="mb-4">
          </div>
          <Form.Group controlId="brand">
            <Form.Label>Python Version</Form.Label>
            <Form.Control
              type="text"
              name="python_version"
              value={formData.python_version}
              onChange={handleChange}
            />
          </Form.Group>
          <div className="mb-4">
          </div>
          <Form.Group controlId="processor">
            <Form.Label>Node Version</Form.Label>
            <Form.Control
              type="text"
              name="node_version"
              value={formData.node_version}
              onChange={handleChange}
            />
          </Form.Group>
          <div className="d-flex justify-content-between align-items-center mt-4">
            <Button variant="primary" type="submit" onClick={handleSubmit}>
              Save
            </Button>
            <Button variant="secondary" onClick={handleCancel}>
              Cancel
            </Button>
          </div>
        {/* </Form> */}
      </Modal.Body>

    </Modal>
  );
};

export default AddLandscapeVersionModal;
