import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import adminRoutes from '../../router/admin.router';

interface IAdminSideBar {
  render?: any
}

export default function AdminSidebar(props: IAdminSideBar) {

  const active_routes = adminRoutes.filter(route => route.navbarShow === true);
  const location = useLocation();
  const locLen = location.pathname.split('/')[2];
  const navigate = useNavigate();
  
  return (
    <div className="d-flex justify-content-start">
      <div className="cdn-sidebar">
        {active_routes.map((data) => (
          <div
            key={data.path}
            style={{ cursor: "pointer" }}
            className={`${data.path === locLen ? "bg-secondary" : ""} cdn-menu-item`}
            onClick={() => navigate(`/admin/${data.path}`)}
          >
            <span className={`${data.path === locLen ? "text-white" : "text-secondary"} h5`}>
              <data.icon />
              <span className='h6 ms-2'>{data.name}</span>
            </span>
          </div>
        ))}
      </div>
      <div className='cdn-main'>{props.render}</div>
    </div>

  )
}