import { useEffect, useState } from 'react';
import { Card, Form, InputGroup } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { HiPlusCircle } from 'react-icons/hi2';
import AddNixExmModal from '../../../components/Model/AddNixExmVersion';
import NixExamTable from '../../../components/Table/nixExam.table';
import NixExmVersionService from '../../../services/public/nixexamVersion.service';

const NixExam = () => {

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchData, setSearchData] = useState<any[]>([]);
  const [show, setShow] = useState<boolean>(false);

  const filteredData = async () => {
    await NixExmVersionService.all().then((res) => {
      if (res.status === 200) {
        setSearchData(res.data);
      }
    }).catch((err) => {
      toast.error('Error while fetching version:', err);
    });
  };




  useEffect(() => {
    filteredData();
  }, [])

  return (
    <>
      <Card className="mx-5 mt-4">
        <Card.Header>
          <h5>
            NIXXAM (STE) Packages
          </h5>
        </Card.Header>
        <div className="d-flex justify-content-end mt-4 me-2">
          <HiPlusCircle className="text-lg text-primary display-6" onClick={() => { setShow(true) }} />
          <Form >
            <InputGroup className="mb-3">
              <Form.Control
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </InputGroup>
          </Form>
        </div>
        <Card.Body>

          <NixExamTable tableData={searchData} reload={filteredData} />
        </Card.Body>
      </Card>
      {show && <AddNixExmModal show={show} setShow={setShow} reload={filteredData} />}
    </>
  )
}

export default NixExam;
