import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap"
import AuthService from "../../services/public/auth.service";
import Select from 'react-select'
import FileService from "../../services/file.service";
import { toast } from "react-hot-toast";
import CdnService from "../../services/public/cdn.service";
import { AiFillEye } from "react-icons/ai"

interface IAddCdnAppModal {
    show: any,
    handleClose: any
    data: any
}

export default function EditCdnAppModal(props: IAddCdnAppModal) {

    const [packageData, setPackageData] = useState<any>();
    const [osData, setOsData] = useState<any>();
    const [cdnData, setCdnData] = useState<any>({});

    const getEnums = async () => {
        await AuthService.getEnums().then((res) => {
            if (res.status === 200) {
                setPackageData(res?.data?.ExamEnum?.PackageType?.map((data: any) => {
                    return {
                        label: data.label,
                        value: data.value
                    }
                }))
                setOsData(res?.data?.DevicesEnum?.OSType?.map((data: any) => {
                    return {
                        label: data.label,
                        value: data.value
                    }
                }))
            }
        })
    }

    const uploadFile = async (e: any) => {
        const files = e.target.files
        const form = new FormData()
        form.append('files', files[0])

        await FileService.uploadFile(form).then((res) => {
            if (res.status === 200) {
                setCdnData({ ...cdnData, s3Url: res.data[0]._id, s3AppName: res.data[0].name })
                toast.success("File Uploaded")
            }
        }).catch(e => {
            toast.error('Error while uploading file', e)
        })
    }


    const handleCreateCdnApp = async () => {

        delete cdnData?.createdAt
        delete cdnData?.updatedAt

        await CdnService.editCdnApp(cdnData._id, cdnData).then((res) => {
            if (res.status === 200) {
                props.handleClose();
            }
        })
    }


    useEffect(() => {
        getEnums();
    }, [])

    useEffect(() => {
        setCdnData(props.data)
    }, [props.show])



    return (
        <Modal show={props.show} onHide={() => props.handleClose()}>
            <Modal.Header closeButton>
                <Modal.Title>Edit CDN App</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Form.Group controlId="model" className="mb-2">
                    <Form.Label>OS Type</Form.Label>
                    <Select
                        value={[{ label: cdnData?.osType, value: cdnData?.osType }]}
                        options={osData}
                        onChange={(e: any) => setCdnData({ ...cdnData, osType: e.value })}

                    />
                </Form.Group>

                <Form.Group controlId="model" className="mb-2">
                    <Form.Label>Package Type</Form.Label>
                    <Select
                        value={[{ label: cdnData?.packageType, value: cdnData?.packageType }]}
                        options={packageData}
                        onChange={(e: any) => setCdnData({ ...cdnData, packageType: e.value })}
                    />
                </Form.Group>
                <Form.Group controlId="model" className="mb-2">
                    <Form.Label>Upload Package<span className="ms-2">{cdnData?.s3AppName && <a href={cdnData?.s3AppName} download className="text-decoration-none"><AiFillEye className="text-primary" /> </a>}</span></Form.Label>
                    <Form.Control
                        type="file"
                        name="s3Url"
                        onChange={(e) => uploadFile(e)}
                    />
                </Form.Group>

                <div className="d-flex justify-content-between align-items-center mt-4">
                    <Button variant="primary" type="submit" onClick={handleCreateCdnApp}>
                        Save
                    </Button>
                    <Button variant="secondary" onClick={() => props.handleClose()}>
                        Cancel
                    </Button>
                </div>
            </Modal.Body>


        </Modal>
    )
}