import { useState } from "react";
import { Button, Form, InputGroup, Table } from "react-bootstrap";
import { AiFillEdit } from "react-icons/ai";
import moment from "moment";
import NixExmVersionService from "../../services/public/nixexamVersion.service";
import { toast } from "react-hot-toast";
import CdnService from "../../services/public/cdn.service";
import { useNavigate } from "react-router-dom";
import EditCdnAppModal from "../Model/EditCdnApp.modal";



interface ICdnTable {
  tableData: any,
  reload?: any,
}

const CdnAppTable = ({ tableData, reload }: ICdnTable) => {

  const [cdnAppIndex, setCdnAppIndex] = useState<number>(-1)

  const handleCdnDelete = async (id: any) => {
    await CdnService.deleteCdnApp(id).then((res) => {
      if (res.status === 200) {
        reload();
        toast.success("Deleted successfully")
      }
    })
  }


  return <>
    <Table hover responsive>
      <thead>
        <tr>
          <th>Sr.No</th>
          <th>OS Type</th>
          <th>Package Type</th>
          <th>File</th>
          <th>Actions</th>

        </tr>
      </thead>
      <tbody className="divide-y">
        {tableData && tableData.length > 0 ? tableData.map((data: any, index: number) => {
          return (
            <tr className="table-light border border-gray-700 bg-white bg-dark" key={index}>
              <td className="nowrap font-weight-medium text-gray-900 text-white">
                <span className="d-flex align-items-center text-dark">
                  {index + 1}
                </span>
              </td>
              <td>{data && data.packageType}</td>
              <td>{data && data.osType}</td>
              <td><a href={data?.s3AppName} download className="text-decoration-none" style={{ cursor: "pointer" }}>Download</a> </td>
              <td>
                <Button size="sm" variant="secondary" onClick={() => setCdnAppIndex(index)} >Edit</Button>
                <Button size="sm" variant="danger" className="ms-2" onClick={() => handleCdnDelete(data?._id)} >Delete</Button>
              </td>
            </tr>
          )
        }) : <tr><td colSpan={12}>No data found</td></tr>}
      </tbody>
    </Table>
    <EditCdnAppModal
      show={cdnAppIndex >= 0 ? true : false}
      handleClose={() => {
        setCdnAppIndex(-1)
        reload()
      }}
      data={tableData && tableData[cdnAppIndex]}
    />
  </>
}

export default CdnAppTable