import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import MappingService from '../../../services/public/mapping.service';
import { Card } from 'react-bootstrap';
import MappingTable from '../../../components/Table/Mapping.table';

export default function CurrentMappings() {

    const [searchData, setSearchData] = useState<any[]>([]);

    const filteredData = async () => {
        await MappingService.getAllExamMapping()
            .then((res) => {
                if (res.status === 200) {
                    setSearchData(res.data);
                }
            })
            .catch((err) => {
                toast.error('Error while fetching devices:', err);
            });
    };

    useEffect(() => {
        filteredData();
    }, []);

    return (
        <Card className="p-2 m-2">
            <Card.Header>
                <h6>
                    Exam Mappings
                </h6>
            </Card.Header>
            <Card.Body>
                <MappingTable tableData={searchData} />
            </Card.Body>
        </Card>
    )
}