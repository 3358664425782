import moment from "moment";
import { useEffect, useState } from "react";
import { Form, Button, Modal, Row, Col } from "react-bootstrap";
import { toast } from "react-hot-toast";
import DevicesService from "../../services/public/devices.service";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import AuthService from "../../services/public/auth.service";

interface FormModel {
  macId: string;

  hardwareId: string;
  serialNumber: string;
  purchaseDate: Date;
  screenSize: string;
  processorModel: string;

  os: string;
  os_version: string;
  ram: string;
  ramType?: string;
  last_checked_on: Date;
  processorMake?: string;
  storageSize: string;
  storageType: string;
  screenResolution?: string;
  screenType?: string;
  screenRefreshRate?: string;
  screenTouch?: string;
  screenHDR?: string;
  screenGSync?: string;
  screenFreeSync?: string;
  biosType?: string;
  biosVersion?: string;
  biosDate?: string;
  gpuType?: string;
  gpuMake?: string;
  gpuSeries?: string;
  gpuModel?: string;
  gpuVram?: string;
  gpuVramType?: string;
  gpuVramBus?: string;
  gpuVramSpeed?: string;
}



const DeviceEditModal = (props: any) => {
 
  const initialFormData: FormModel = {} as FormModel;
  const [formData, setFormData] = useState<FormModel>(initialFormData);
  const [deviceData, setDeviceData] = useState<any>({});




  const [devicesEnum, setDevicesEnum] = useState<any>({});

  console.log("Initial Processor Make:", initialFormData?.processorMake);
console.log("hello")
  // console.log(props)


  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const getDeviceDataById = async()=>{
    DevicesService.getDeviceDataById(props.id).then((res)=>{
      if(res.status===200){
        setDeviceData(res.data)
        console.log(res.data)
        setFormData((prevState) => ({
          ...prevState,
          macId: res.data.macId,
          hardwareId: res.data.hardwareId,
          serialNumber: res.data.serialNumber,
          // purchaseDate: res.data.purchaseDate,
          screenSize: res.data.screenSize,
          processorModel: res.data.processorModel,
          os: res.data.os,
          os_version: res.data.os_version,
          ram: res.data.ram,
          ramType: res.data.ramType,
          // last_checked_on: res.data.last_checked_on,
          processorMake: res.data.processorMake,
          storageSize: res.data.storageSize,
          storageType: res.data.storageType,
          screenResolution: res.data.screenResolution,
          screenType: res.data.screenType,
          screenRefreshRate: res.data.screenRefreshRate,
          screenTouch: res.data.screenTouch,
          screenHDR: res.data.screenHDR,
          screenGSync: res.data.screenGSync,
          screenFreeSync: res.data.screenFreeSync,
          biosType: res.data.biosType,
          biosVersion: res.data.biosVersion,
          biosDate: res.data.biosDate,
          gpuType: res.data.gpuType,
          gpuMake: res.data.gpuMake,
          gpuSeries: res.data.gpuSeries,
          gpuModel: res.data.gpuModel,
          gpuVram: res.data.gpuVram,
          gpuVramType: res.data.gpuVramType,
          gpuVramBus: res.data.gpuVramBus,
          gpuVramSpeed: res.data.gpuVramSpeed,
        }));

  }
    }
    ).catch((err)=>{
      toast.error('Error while getting device data:', err);
    })
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    await DevicesService.update(formData, props?.id).then((res) => {
      if (res.status === 200) {
        props.setShow(false);
        window.location.reload();
      }
    }).catch((err) => {
      toast.error('Error while updating devices:', err);
    });
  };

  function isValidMacAddress(): boolean {
    // Regular expression to match a valid MAC address
    const macAddressPattern = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;

    return macAddressPattern.test(formData?.macId?.toUpperCase());
  }
  const getEnums = async () => {
    await AuthService.getEnums().then((res) => {
      if (res.status === 200) {
        setDevicesEnum(res.data.DevicesEnum)
        console.log(res.data)
      }
    })
  }

  const handleCancel = () => {
    props.setShow(false)
  }

  useEffect(() => {
    getEnums();
    if(props.id ){

      getDeviceDataById();
    }
  }, [])
  return (
    <Modal show={props.show} onHide={() => props.setShow(false)} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Edit Device</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <Row className="mt-2">
          <Col>
            <Form.Group>
              <Form.Label>Processor Make</Form.Label>
              <Select
                options={devicesEnum?.ProcessorMake}
                onChange={(e: any) => setFormData((prevState) => ({
                  ...prevState,
                  processorMake: e.value,
                }))}
                value={devicesEnum?.ProcessorMake?.filter((e: any) => e.value === formData.processorMake)}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="processor">
              <Form.Label>Processor</Form.Label>
              <Select
                options={devicesEnum?.ProcessorModel}
                onChange={(e: any) => setFormData((prevState) => ({
                  ...prevState,
                  processorModel: e.value,
                }))}
                value={devicesEnum?.ProcessorModel?.filter((e: any) => e.value === formData.processorModel)}
              />
            </Form.Group>
          </Col>


        </Row>

        <Row className="mt-2">
          <Col>
            <Form.Group controlId="ram">
              <Form.Label>RAM</Form.Label>
              <Select
                options={devicesEnum?.RamSize}
                onChange={(e: any) => setFormData((prevState) => ({
                  ...prevState,
                  ram: e.value,
                }))}
                value={devicesEnum?.RamSize?.filter((e: any) => e.value === formData.ram)}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="ram">
              <Form.Label>RAM Type</Form.Label>
              <Select
                options={devicesEnum?.RamType}
                onChange={(e: any) => setFormData((prevState) => ({
                  ...prevState,
                  ramType: e.value,
                }))}
                value={devicesEnum?.RamType?.filter((e: any) => e.value === formData.ramType)}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mt-2">
          <Col>
            <Form.Group controlId="storageSize">
              <Form.Label>Storage Size</Form.Label>
              <Select
                options={devicesEnum?.StorageSize}
                onChange={(e: any) => setFormData((prevState) => ({
                  ...prevState,
                  storageSize: e.value,
                }))}
                value={devicesEnum?.StorageSize?.filter((e: any) => e.value === formData.storageSize)}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="storageType">
              <Form.Label>Storage Type</Form.Label>
              <Select
                options={devicesEnum?.StorageType}
                onChange={(e: any) => setFormData((prevState) => ({
                  ...prevState,
                  storageType: e.value,
                }))}
                value={devicesEnum?.StorageType?.filter((e: any) => e.value === formData.storageType)}
              />
            </Form.Group>
          </Col>
        </Row>
        <hr />
        <Row className="mt-2">
          <Col>
            <Form.Group controlId="screenSize">
              <Form.Label>Screen Size</Form.Label>
              <Select
                options={devicesEnum?.ScreenSize}
                onChange={(e: any) => setFormData((prevState) => ({
                  ...prevState,
                  screenSize: e.value,
                }))}
                value={devicesEnum?.ScreenSize?.filter((e: any) => e.value === formData.screenSize)}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="screenResolution">
              <Form.Label>Screen Resolution</Form.Label>
              <Select
                options={devicesEnum?.ScreenResolution}
                onChange={(e: any) => setFormData((prevState) => ({
                  ...prevState,
                  screenResolution: e.value,
                }))}
                value={devicesEnum?.ScreenResolution?.filter((e: any) => e.value === formData.screenResolution)}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="screenType">
              <Form.Label>Screen Type</Form.Label>
              <Select
                options={devicesEnum?.ScreenType}
                onChange={(e: any) => setFormData((prevState) => ({
                  ...prevState,
                  screenType: e.value,
                }))}
                value={devicesEnum?.ScreenType?.filter((e: any) => e.value === formData.screenType)}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="screenRefreshRate">
              <Form.Label>Screen Refresh Rate</Form.Label>
              <Select
                options={devicesEnum?.ScreenRefreshRate}
                onChange={(e: any) => setFormData((prevState) => ({
                  ...prevState,
                  screenRefreshRate: e.value,
                }))}
                value={devicesEnum?.ScreenRefreshRate?.filter((e: any) => e.value === formData.screenRefreshRate)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <Form.Group controlId="screenTouch">
              <Form.Label>Screen Touch</Form.Label>
              <Select
                options={devicesEnum?.ScreenTouch}
                onChange={(e: any) => setFormData((prevState) => ({
                  ...prevState,
                  screenTouch: e.value,
                }))}
                value={devicesEnum?.ScreenTouch?.filter((e: any) => e.value === formData.screenTouch)}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="screenHDR">
              <Form.Label>Screen HDR</Form.Label>
              <Select
                options={devicesEnum?.ScreenHDR}
                onChange={(e: any) => setFormData((prevState) => ({
                  ...prevState,
                  screenHDR: e.value,
                }))}
                value={devicesEnum?.ScreenHDR?.filter((e: any) => e.value === formData.screenHDR)}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="screenGSync">
              <Form.Label>Screen GSync</Form.Label>
              <Select
                options={devicesEnum?.ScreenGSync}
                onChange={(e: any) => setFormData((prevState) => ({
                  ...prevState,
                  screenGSync: e.value,
                }))}
                value={devicesEnum?.ScreenGSync?.filter((e: any) => e.value === formData.screenGSync)}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="screenFreeSync">
              <Form.Label>Screen FreeSync</Form.Label>
              <Select
                options={devicesEnum?.ScreenFreeSync}
                onChange={(e: any) => setFormData((prevState) => ({
                  ...prevState,
                  screenFreeSync: e.value,
                }))}
                value={devicesEnum?.ScreenFreeSync?.filter((e: any) => e.value === formData.screenFreeSync)}
              />
            </Form.Group>
          </Col>
        </Row>
        <hr />

        <Row className="mt-2">
          <Col>
            <Form.Group controlId="biosType">
              <Form.Label>BIOS Type</Form.Label>
              <Select
                options={devicesEnum?.BIOSType}
                onChange={(e: any) => setFormData((prevState) => ({
                  ...prevState,
                  biosType: e.value,
                }))}
                value={devicesEnum?.BIOSType?.filter((e: any) => e.value === formData.biosType)}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="biosVersion">
              <Form.Label>BIOS Version</Form.Label>
              <Select
                options={devicesEnum?.BIOSVersion}
                onChange={(e: any) => setFormData((prevState) => ({
                  ...prevState,
                  biosVersion: e.value,
                }))}
                value={devicesEnum?.BIOSVersion?.filter((e: any) => e.value === formData.biosVersion)}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="biosDate">
              <Form.Label>BIOS Date</Form.Label>
              <Select
                options={devicesEnum?.BIOSDate}
                onChange={(e: any) => setFormData((prevState) => ({
                  ...prevState,
                  biosDate: e.value,
                }))}
                value={devicesEnum?.BIOSDate?.filter((e: any) => e.value === formData.biosDate)}
              />
            </Form.Group>
          </Col>
        </Row>
        <hr />

        <Row className="mt-2">
          <Col>
            <Form.Group controlId="gpuType">
              <Form.Label>GPU Type</Form.Label>
              <Select
                options={devicesEnum?.GPUType}
                onChange={(e: any) => setFormData((prevState) => ({
                  ...prevState,
                  gpuType: e.value,
                }))}
                value={devicesEnum?.GPUType?.filter((e: any) => e.value === formData.gpuType)}
              />
            </Form.Group>

          </Col>
          <Col>
            <Form.Group controlId="gpuMake">
              <Form.Label>GPU Make</Form.Label>
              <Select
                options={devicesEnum?.GPUMake}
                onChange={(e: any) => setFormData((prevState) => ({
                  ...prevState,
                  gpuMake: e.value,
                }))}
                value={devicesEnum?.GPUMake?.filter((e: any) => e.value === formData.gpuMake)}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="gpuSeries">
              <Form.Label>GPU Series</Form.Label>
              <Select
                options={devicesEnum?.GPUSeries}
                onChange={(e: any) => setFormData((prevState) => ({
                  ...prevState,
                  gpuSeries: e.value,
                }))}
                value={devicesEnum?.GPUSeries?.filter((e: any) => e.value === formData.gpuSeries)}
              />
            </Form.Group>

          </Col>
          <Col>
            <Form.Group controlId="gpuModel">
              <Form.Label>GPU Model</Form.Label>
              <Select
                options={devicesEnum?.GPUModel}
                onChange={(e: any) => setFormData((prevState) => ({
                  ...prevState,
                  gpuModel: e.value,
                }))}
                value={devicesEnum?.GPUModel?.filter((e: any) => e.value === formData.gpuModel)}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="gpuVram">
              <Form.Label>GPU VRAM</Form.Label>
              <Select
                options={devicesEnum?.GPUVRAM}
                onChange={(e: any) => setFormData((prevState) => ({
                  ...prevState,
                  gpuVram: e.value,
                }))}
                value={devicesEnum?.GPUVRAM?.filter((e: any) => e.value === formData.gpuVram)}
              />
            </Form.Group>

          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            <Form.Group controlId="gpuVramType">
              <Form.Label>GPU VRAM Type</Form.Label>
              <Select
                options={devicesEnum?.GPUVRAMType}
                onChange={(e: any) => setFormData((prevState) => ({
                  ...prevState,
                  gpuVramType: e.value,
                }))}
                value={devicesEnum?.GPUVRAMType?.filter((e: any) => e.value === formData.gpuVramType)}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="gpuVramBus">
              <Form.Label>GPU VRAM Bus</Form.Label>
              <Select
                options={devicesEnum?.GPUVRAMBus}
                onChange={(e: any) => setFormData((prevState) => ({
                  ...prevState,
                  gpuVramBus: e.value,
                }))}
                value={devicesEnum?.GPUVRAMBus?.filter((e: any) => e.value === formData.gpuVramBus)}
              />
            </Form.Group>

          </Col>
          <Col>
            <Form.Group controlId="gpuVramSpeed">
              <Form.Label>GPU VRAM Speed</Form.Label>
              <Select
                options={devicesEnum?.GPUVRAMSpeed}
                onChange={(e: any) => setFormData((prevState) => ({
                  ...prevState,
                  gpuVramSpeed: e.value,
                }))}
                value={devicesEnum?.GPUVRAMSpeed?.filter((e: any) => e.value === formData.gpuVramSpeed)}
              />
            </Form.Group>
          </Col>
        </Row>
        <hr />
        <Row className="mt-2">
          <Col>
            <Form.Group controlId="macId">
              <Form.Label>Mac Id</Form.Label>
              <Form.Control
                type="text"
                name="macId"
                value={formData.macId}
                onChange={handleChange}
                isInvalid={isValidMacAddress()}
              />
              {!isValidMacAddress() && (
                <Form.Control.Feedback type="invalid">
                  Please enter a valid MAC address (e.g., 00:1A:2B:3C:4D:5E).
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="hardwareId">
              <Form.Label>Hardware Id</Form.Label>
              <Form.Control
                type="text"
                name="hardwareId"
                value={formData.hardwareId}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="serialNumber">
              <Form.Label>Serial Number</Form.Label>
              <Form.Control
                type="text"
                name="serialNumber"
                value={formData.serialNumber}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-2">
          {/* <Col>
            <Form.Group controlId="purchaseDate">
              <Form.Label>Purchase Date</Form.Label>

            </Form.Group>
            <DatePicker
              className="form-control"
              selected={formData.purchaseDate}
              onChange={(date: Date) => setFormData((prevState) => ({
                ...prevState,
                purchaseDate: date,
              }))}
            />
          </Col> */}
        </Row>
        <Row className="mt-2">
          <Col>
            <Form.Group controlId="os">
              <Form.Label>OS</Form.Label>
              <Select
                options={devicesEnum?.OSType}
                onChange={(e: any) => setFormData((prevState) => ({
                  ...prevState,
                  os: e.value,
                }))}
                value={devicesEnum?.OSType?.filter((e: any) => e.value === formData.os)}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="os_version">
              <Form.Label>OS Version</Form.Label>
              <Form.Control
                type="text"
                name="os_version"
                value={formData.os_version}
                onChange={handleChange}
              />
            </Form.Group>
          </Col>

          {/* <Col>
            <Form.Group controlId="last_checked_on">
              <Form.Label>Last Checked On</Form.Label>
            </Form.Group>
            <DatePicker
              className="form-control"
              selected={formData.last_checked_on}
              onChange={(date: Date) => setFormData((prevState) => ({
                ...prevState,
                last_checked_on: date,
              }))}
            />
          </Col> */}
        </Row>

        <div className="d-flex justify-content-end align-items-center mt-4">
          <Button variant="primary" 
          
          onClick={handleSubmit}
          >
            Update
          </Button>
          <Button variant="secondary" className="ms-2" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </Modal.Body>

    </Modal>
  );
};

export default DeviceEditModal;
