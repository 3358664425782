import { Button, Card, Form, InputGroup } from "react-bootstrap";
import AddCdnAppModal from "../../../../components/Model/AddCdnApp.modal";
import { useEffect, useState } from "react";
import CdnService from "../../../../services/public/cdn.service";
import { HiPlusCircle } from 'react-icons/hi2';
import CdnAppTable from "../../../../components/Table/cdnApp.table";
export default function CdnApp() {

    const [showCdnApp, setShowCdnApp] = useState<boolean>(false);
    const [cdnData, setCdnData] = useState<any>();


    const getAllData = async () => {
        await CdnService.get().then((res) => {
            if (res.status === 200) {
                console.log(res.data)
                setCdnData(res.data)
            }
        })
    }

    useEffect(() => {
        getAllData()
    }, [])

    return (
        <>
            <Card className="mx-5 mt-4">
                <Card.Header>
                    <h5>
                        CDN App
                    </h5>
                </Card.Header>
                <div className="d-flex justify-content-end mt-4 me-2">
                    <HiPlusCircle className="text-lg text-primary display-6" onClick={() => { setShowCdnApp(true) }} />
                    <Form >
                        <InputGroup className="mb-3">
                            <Form.Control
                                type="text"
                                placeholder="Search..."
                            // value={searchTerm}
                            // onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </InputGroup>
                    </Form>
                </div>
                <Card.Body>

                    <CdnAppTable tableData={cdnData} reload={getAllData} />
                </Card.Body>
            </Card>
            <AddCdnAppModal show={showCdnApp} setShow={setShowCdnApp} />
        </>
    )
}