import moment from "moment";
import { useEffect, useState } from "react";
import { Form, Button, Modal, Alert } from "react-bootstrap";
import { toast } from "react-hot-toast";
import DevicesService from "../../services/public/devices.service";
import NixExmVersionService from "../../services/public/nixexamVersion.service";
import Select from "react-select";
import AuthService from "../../services/public/auth.service";
import FileService from "../../services/file.service";
import DatePicker from "react-datepicker"
import { AiFillEye } from "react-icons/ai"


interface IAddNixExmModal {
  show: boolean,
  handleClose: any,
  data: any
}

const EditNixExmModal = (props: IAddNixExmModal) => {

  const [formData, setFormData] = useState<any>({});
  const [enums, setEnums] = useState<any>({});


  const handleSubmit = async (e: any) => {

    delete formData?.createdAt
    delete formData?.updatedAt

    const payload = {
      appHash: formData?.appHash,
      dockerVersion: formData?.dockerVersion,
      lastCommitId: formData?.lastCommitId,
      nodeVersion: formData?.nodeVersion,
      platform: formData?.platform,
      s3AppName: formData?.s3AppName,
      s3Url: formData?.s3Url,
      versionDate: formData?.versionDate
    }

    await NixExmVersionService.updateSte(formData?._id, payload).then((res) => {
      if (res.status === 200) {
        toast.success("STE updated successfully")
        props.handleClose()
      }
    })
  };


  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }



  const getEnums = async () => {
    await AuthService.getEnums().then((res) => {
      if (res.status === 200) {
        setEnums(res.data)
      }
    })
  }

  const uploadFile = async (e: any) => {
    const files = e.target.files
    const form = new FormData()
    form.append('files', files[0])

    await FileService.uploadFile(form).then((res) => {
      if (res.status === 200) {
        setFormData({ ...formData, s3Url: res.data[0]._id, s3AppName: res.data[0].name })
        toast.success("File Uploaded")
      }
    }).catch(e => {
      toast.error('Error while uploading file', e)
    })
  }

  useEffect(() => {
    getEnums()
  }, [])

  useEffect(() => {
    setFormData(props.data)
  }, [props.show])

  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add New EXMSTE Package</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="macId" className="mb-2">
          <Form.Label>App Hash</Form.Label>
          <Form.Control
            type="text"
            name="appHash"
            value={formData?.appHash}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group controlId="model" className="mb-2">
          <Form.Label>Version Number</Form.Label>
          <Form.Control
            type="text"
            name="lastCommitId"
            value={formData?.lastCommitId}
            onChange={handleChange}
          />
        </Form.Group>


        <Form.Group controlId="model" className="mb-2">
          <Form.Label>OS Type</Form.Label>
          <Select
            options={enums && enums.DevicesEnum && enums.DevicesEnum.OSType}
            value={{ label: formData?.platform, value: formData?.platform }}
            onChange={(e: any) => setFormData({ ...formData, platform: e.value })}
          />
        </Form.Group>

        <Form.Group controlId="model" className="mb-2">
          <Form.Label>Version Date</Form.Label>
          <div style={{ width: "100%" }}>
            <DatePicker className="w-100 form-control" selected={formData?.versionDate ? moment(formData?.versionDate).toDate() : new Date()} onChange={(date: any) => setFormData({ ...formData, versionDate: date })} />
          </div>
        </Form.Group>

        <Form.Group controlId="model" className="mb-2">
          <Form.Label>Docker Version</Form.Label>
          <Form.Control
            type="text"
            name="dockerVersion"
            value={formData?.dockerVersion}
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group controlId="model" className="mb-2">
          <Form.Label>Node Version</Form.Label>
          <Form.Control
            type="text"
            name="nodeVersion"
            value={formData?.nodeVersion}
            onChange={handleChange}
          />
        </Form.Group>

        <Alert variant="warning" className="mt-3">
          <b>Note:</b> Make sure to upload the package before saving the form. Also ensure you are uploading correct docker file in a zip format with no subdirectories.
        </Alert>

        <Form.Group controlId="model" className="mb-2">
          <Form.Label>Upload Package<span className="ms-2">{formData?.s3AppName && <a href={formData?.s3AppName} download className="text-decoration-none"><AiFillEye className="text-primary" /> </a>}</span></Form.Label>
          <Form.Control
            type="file"
            name="s3Url"
            onChange={(e) => uploadFile(e)}
          />
        </Form.Group>

        <div className="d-flex justify-content-between align-items-center mt-4">
          <Button variant="primary" type="submit" onClick={handleSubmit}>
            Save
          </Button>
          <Button variant="secondary" onClick={props.handleClose}>
            Cancel
          </Button>
        </div>
      </Modal.Body>


    </Modal>
  );
};

export default EditNixExmModal;
