import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Button, Card, Col, Form, Row, Table } from 'react-bootstrap';
import MappingModal from '../../../components/Model/AddMappingModal';
import MappingTable from '../../../components/Table/Mapping.table';
import MappingService from '../../../services/public/mapping.service';
import { toast } from 'react-hot-toast';
import ExamService from '../../../services/public/exam.service';
import DevicesService from '../../../services/public/devices.service';
import AuthService from '../../../services/public/auth.service';
import NixExmVersionService from '../../../services/public/nixexamVersion.service';


const Mapping = () => {
  const [selectedExam, setSelectedExam] = useState<any>(null);
  const [selectedShift, setSelectedShift] = useState<any>(null);
  const [selectedVersion, setSelectedVersion] = useState<any>(null);
  const [selectedExamType, setSelectedExamType] = useState<any>(null);
  const [selectedExamSTE, setSelectedExamSTE] = useState<any>(null);
  const [selectedExamWALL, setSelectedExamWALL] = useState<any>(null);

  const [showModal, setShowModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [versionData, setVerionData] = useState<any[]>([]);
  const [wallData, setWallData] = useState<any[]>([]);

  const [exams, setExams] = useState<any[]>([])

  const [devices, setDevices] = useState<any[]>([])
  const [enums, setEnums] = useState<any>()

  const [selectedDevices, setSelectedDevices] = useState<any[]>([]);
  const [selectedDevicesData, setSelectedDevicesData] = useState<any[]>([]);


  const handleExamChange = (selectedOption: any) => {
    setSelectedExam(selectedOption);
    setSelectedShift(null);
  };

  const handleShiftChange = (selectedOption: any) => {
    setSelectedShift(selectedOption);
  };


  const updateSelection = (id: string) => {
    const index = selectedDevices.findIndex((device) => device === id)
    if (index === -1) {
      setSelectedDevices([...selectedDevices, id])
    } else {
      const newDevices = selectedDevices.filter((device) => device !== id)
      setSelectedDevices(newDevices)
    }
  }


  const getExams = async () => {
    await ExamService.getExams(1, 1000000).then((res) => {
      if (res.status == 200) {
        setExams(res.data)
      }
    })
  }

  const getDevices = async () => {
    await DevicesService.all().then(res => {
      if (res.status == 200) {
        setDevices(res.data)
      }
    })
  }


  const getEnums = async () => {
    await AuthService.getEnums().then((res) => {
      if (res.status == 200) {
        setEnums(res.data)
      }
    })
  }

  const getSelectedDevices = () => {
    let filteredDevices = devices.filter((device) => selectedDevices.includes(device._id));
    setSelectedDevicesData(filteredDevices);
  }

  const getVersions = async () => {
    await NixExmVersionService.all().then((res) => {
      if (res.status === 200) {
        setVerionData(res.data);
      }
    }).catch((err) => {
      toast.error('Error while fetching version:', err);
    });
  };

  const getAllWalls = async () => {
    await NixExmVersionService.wallAll().then((res) => {
      if (res.status === 200) {
        setWallData(res.data);
      }
    }).catch((err) => {
      toast.error('Error while fetching version:', err);
    });

  };

  const handleSubmit = async () => {
    const payload = {
      examId: selectedExam._id,
      shiftId: selectedShift._id,
      appType: selectedExamType.value,
      steApp: selectedExamSTE.value,
      wallApp: selectedExamWALL.value,
      deviceId: selectedDevices
    };

    MappingService.createExamMapping(payload)
      .then((res) => {
        if (res.status === 200) {
          toast.success('Mapping Created Successfully');
        }
      })
      .catch((err) => {
        toast.error('Error while fetching devices:', err);
      })
  };

  useEffect(() => {
    getExams()
    getDevices()
    getEnums()
    getVersions()
    getAllWalls()
  }, [])

  useEffect(() => {
    getSelectedDevices();
    console.log(selectedDevicesData)
  }, [selectedDevices])

  return (
    <>
      <Card className="p-2 m-2">
        <Card.Body>
          <Row className="mt-2">
            <Col>
              <h6>1. Select an Exam</h6>
              <Select options={exams.map((exam) => {
                return {
                  value: exam._id,
                  label: exam.examName,
                  shifts: exam.shifts
                }
              })} value={selectedExam} onChange={handleExamChange} />
            </Col>
            <Col>
              <h6>2. Select a Shift</h6>
              <Select options={selectedExam && selectedExam.shifts.map((shift: any) => {
                return {
                  value: shift._id,
                  label: shift.shiftName,
                }
              })} value={selectedShift} onChange={handleShiftChange} isDisabled={!selectedExam} />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col md='4'>
              <h6>3. Exam Type</h6>
              <Select options={enums && enums.ExamEnum && enums.ExamEnum.ExamTypes} value={selectedExamType} onChange={setSelectedExamType} />
            </Col>
            <Col md='4'>
              <h6>4. Exam STE</h6>
              <Select options={versionData && versionData.map((site) => {
                return {
                  value: site._id,
                  label: site.lastCommitId,
                }
              })} value={selectedExamSTE} onChange={setSelectedExamSTE} />
            </Col>
            <Col md='4'>
              <h6>5. Exam WALL</h6>
              <Select options={wallData && wallData.map((wall) => {
                return {
                  value: wall._id,
                  label: wall.lastCommitId,
                }
              })} value={selectedExamWALL} onChange={setSelectedExamWALL} />
            </Col>
            <Col>
            </Col>
          </Row>


          <Row className="mt-4">
            <Col>
              <h6>
                Available Devices ({devices.length})
              </h6>
              <Table striped bordered size="sm">
                <thead>
                  <tr>
                    <th>

                    </th>
                    <th>
                      S.no.
                    </th>
                    <th>
                      Mac
                    </th>
                    <th>
                      Processor Make
                    </th>
                    <th>
                      Processor Model
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    devices.map((device: any, index: number) => {
                      return (
                        <>
                          <tr onClick={() => updateSelection(device._id)}>
                            <td>
                              <Form.Check
                                id="custom-switch"
                                checked={selectedDevices.includes(device._id)}
                              />
                            </td>
                            <td>
                              {index + 1}
                            </td>
                            <td>
                              {device.macId}
                            </td>
                            <td>
                              {device.processorMake}
                            </td>
                            <td>
                              {device.processor || device.processorModel}
                            </td>
                          </tr>
                        </>
                      )
                    })
                  }
                </tbody>
              </Table>
            </Col>
            <Col>
              <h6>
                Selected Devices ({selectedDevices.length})
              </h6>
              <Table striped bordered size="sm">
                <thead>
                  <tr>
                    <th>
                      S.no.
                    </th>
                    <th>
                      Mac
                    </th>
                    <th>
                      Processor Make
                    </th>
                    <th>
                      Processor Model
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    selectedDevices.length > 0 ?
                      selectedDevicesData.map((device: any, index: number) => {
                        return (
                          <>
                            <tr onClick={() => updateSelection(device._id)}>
                              <td>
                                {index + 1}
                              </td>
                              <td>
                                {device.macId}
                              </td>
                              <td>
                                {device.processorMake}
                              </td>
                              <td>
                                {device.processor}
                              </td>
                            </tr>
                          </>
                        )
                      }) :
                      <tr className='text-center'>
                        <td colSpan={4}>
                          No Devices Selected
                        </td>
                      </tr>
                  }
                </tbody>
              </Table>
            </Col>
          </Row>
          <Button className='d-flex justify-content-between align-items-end' variant="primary" onClick={handleSubmit}>
            Set Mapping
          </Button>


          {/* {selectedShift && (
            <MappingModal
              show={showModal}
              setShow={setShowModal}
              shifts={[]}
              exams={exams}
              selectedShift={selectedShift}
              setSelectedShift={setSelectedShift}
              selectedExam={selectedExam}
              setSelectedExam={setSelectedExam}
              setSelectedVersion={setSelectedVersion}
              selectedVersion={selectedVersion}
              versions={[]}
            />
          )}*/}
        </Card.Body>
      </Card>
    </>
  );
};

export default Mapping;
