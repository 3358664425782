import { useState } from 'react';
import { Card } from 'react-bootstrap';




const LiveSessions = () => {
    
    return (
        <>
            <Card className="mx-5 mt-4 p-4">
                <Card.Body>
                    <h6>No live sesions scheduled now!</h6>
                </Card.Body>
            </Card>
        </>
    );
};

export default LiveSessions;