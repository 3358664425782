import { useState } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";
import DevicesService from "../../services/public/devices.service";
import ExamService from "../../services/public/exam.service";

interface FormModel {
  examName: string;
  examCldId: string;
  shifts: { shiftName: string, exmCldShiftId: string }[];
}


const EditExamModal = (props: any) => {
  const initialFormData: FormModel = {
    examName: '',
    examCldId: '',
    shifts: [{ shiftName: '', exmCldShiftId: '' }]
  }
  const [formData, setFormData] = useState<FormModel>(initialFormData);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === "shiftName" || name === "exmCldShiftId") {
      const index = parseInt(event.target.getAttribute("data-index") as string);
      setFormData((prevState) => {
        const shifts = [...prevState.shifts];
        shifts[index] = {
          ...shifts[index],
          [name]: value,
        };
        return {
          ...prevState,
          shifts,
        };
      });
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async () => {
    // event.preventDefault();
    const shifts = formData.shifts.map((shift) => ({
      shiftName: shift.shiftName,
      exmCldShiftId: shift.exmCldShiftId,
    }));
    const data = {
      examName: formData.examName,
      examCldId: formData.examCldId,
      shifts,
    };
    await ExamService.create(data)
      .then((res) => {
        if (res.status === 200) {
          props.setShow(false);
          window.location.reload();
        }
      })
      .catch((err) => {
        toast.error("Error while creating Exam:", err);
      });
  };

  const handleCancel = () => {
    props.setShow(false)
  }
  return (
    <Modal show={props.show} onHide={() => props.setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>ADD</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <Form onSubmit={handleSubmit}> */}
          <div className="mt-3">
            <Form.Group controlId="examName">
              <Form.Label>Exam Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter exam name"
                value={formData.examName}
                onChange={handleChange}
                name='examName'
              />
            </Form.Group>
          </div>
          <div className="mt-3">
            <Form.Group controlId="examCldId">
              <Form.Label>ExmCld Id</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter exam cld id"
                value={formData.examCldId}
                onChange={handleChange}
                name="examCldId"
              />
            </Form.Group>
          </div>

          {formData.shifts.map((shift, index) => (
            <div key={index} className="mt-3">
              <Form.Group controlId="shiftName">
                <Form.Label>Shift Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter shift name"
                  value={shift.shiftName}
                  data-index={index}
                  onChange={handleChange}
                  name="shiftName"
                />
              </Form.Group>
              <div key={index} className="mt-3">

                <Form.Group controlId="exmCldShiftId">
                  <Form.Label>ExmCld ShiftId</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter examcld shiftid"
                    value={shift.exmCldShiftId}
                    data-index={index}
                    onChange={handleChange}
                    name="exmCldShiftId"
                  />
                </Form.Group>
              </div>
            </div>
          ))}


          <div className="d-flex justify-content-between align-items-center mt-4">
            <Button variant="primary" type="submit" onClick={handleSubmit}>
              Add Shift
            </Button>
            <Button variant="secondary" onClick={handleCancel}>
              Cancel
            </Button>
          </div>

        {/* </Form> */}
      </Modal.Body>
    </Modal>
  );
};

export default EditExamModal;
