import { useState } from "react";
import { Table } from "react-bootstrap";
import { AiFillEdit } from "react-icons/ai";
import EditExamModal from "../Model/EditExam.modal";

interface ITable {
    tableData: any,
}


const ExamTable = ({ tableData }: ITable) => {
  const [edit,setEdit]=useState<boolean>(false);
  const [index,setIndex]=useState<number>(0);
   return <>
   <Table hover responsive>
  <thead>
    <tr>
      <th>SR.No</th>
      <th>Exam Name</th>
      <th>examCld Id</th>
      <th>shiftName</th>
      <th>exmCld ShiftId</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody className="divide-y">
          {tableData && tableData.length > 0 ? tableData.map((data: any, index: number) => {
            return (
              <tr className="table-light border border-gray-700 bg-white bg-dark" key={index}>
                <td className="nowrap font-weight-medium text-gray-900 text-white">
                  <span className="d-flex align-items-center text-dark">
                    {index + 1}
                  </span>
                </td>
                <td>{data && data.examName}</td>
                <td>{data && data.examCldId}</td>
                {data?.shifts.map((shift:any)=>{
                  return <> <td>{shift && shift.shiftName}</td>
                <td>{shift && shift.exmCldShiftId}</td>
                </>
                })}
                
                <td>
                  <AiFillEdit onClick={()=>{setEdit(true); setIndex(index)}}/>
                </td>
              </tr>
            )
          }) : <tr><td colSpan={12}>No data found</td></tr>}
        </tbody>
</Table>
 {edit && <EditExamModal show={edit} setShow={setEdit} data={tableData[index]} id={tableData[index]._id}/>}

</>
}

export default ExamTable