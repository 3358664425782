import { useEffect, useState } from 'react';
import { Card, Form, InputGroup } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { HiPlusCircle } from 'react-icons/hi2';
import AddExamModal from '../../../components/Model/AddExam.modal';
import ExamTable from '../../../components/Table/Exam.table';
import DevicesService from '../../../services/public/devices.service';
import ExamService from '../../../services/public/exam.service';

const Exam = () => {
 const [searchTerm, setSearchTerm] = useState<string>('');
const [searchData, setSearchData] = useState<any[]>([]);
const [show,setShow] = useState<boolean>(false);


 const filteredData = () => {
    setTimeout(async () => {
      await ExamService.all(searchTerm).then((res) => {
        if (res.status === 200) {
          setSearchData(res.data);
        }
      }).catch((err) => {
        toast.error('Error while fetching devices:', err);
      });
    }, 500);
  };


  useEffect(()=>{
  filteredData();;
  },[searchTerm])

console.log(searchData,'sd')
  return (
    <>
  <Card className="mx-5 mt-4">
     
  <Card.Header>
    
    <h5>
      Exams
    </h5>
  </Card.Header>
  <div className="d-flex justify-content-end mt-4 me-2">
 <HiPlusCircle className="text-lg text-primary display-6" onClick={()=>{setShow(true)}} />
   <Form >
        <InputGroup className="mb-3">
          <Form.Control
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </InputGroup>
      </Form>
      </div>
  <Card.Body>
   <ExamTable tableData={searchData}/>
  </Card.Body>
</Card>
{show && <AddExamModal show={show} setShow={setShow}/>}
</>
  )
}

export default Exam;