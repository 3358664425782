
const urls = {
  public: {
    auth: {
      login: "/public/adminlogin",
    },
    getEnums: "/public/enums/get"
  },
  private: {
    versions: {
      all: "/version/get",
      create: "/version/create",
      update: "/version/update",
      byId: "/version/get/",
    },
    devices: {
      all: "/device/get",
      create: "/device/create",
      byId: "/device/getDeviceById/",
      update: "/device/update/",
      delete:"/device/deleteDevice/",
    },
    nixExmVersion: {
      all: "/steapp/getAllSteApp",
      create: "/app/version/create",
      byId: "/app/version/get/",
      update: "/app/version/update/",
      wallAll: "/wallapp/getAllWallApp",
      createSte: "/steapp/createSteApp",
      createWall: "/wallapp/createWallApp",
      deleteSte: "/steapp/deleteSteApp",
      deleteWall: "/wallapp/deletewallApp",
      updateSte: "/steapp/updateSteApp",
      updateWall: "/wallapp/updateWallApp"
    },
    landscapeVersion: {
      all: "/version/get",
      create: "/version/create",
      byId: "/version/get/",
      update: "/version/update/",
    },
    exam: {
      all: "/exam/get",
      create: "/exam/create",
      byId: "/exam/get/",
      update: "/exam/update/",
    },
    mapping: {
      all: "/examMapping/getAllMapping",
      create: "/examMapping/create",
    },
    file: {
      upload: "/s3/uploadFile"
    },

    cdnApp: {
      create: "/cdnDashboardApp/create",
      get: "/cdnDashboardApp/getAllAppData",
      delete: "/cdnDashboardApp/delete",
      cdnAppById: "/cdnDashboardApp/getAppDataById",
      update: "/cdnDashboardApp/update"
    }
  }
};

export default urls;
