import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import MainRouter from "./Router";
import { LoadingIndicator } from "./context";



function App() {

  const [loading, setLoading] = React.useState<any>(undefined); // add text in setLoading to display text in loader

  // useEffect(() => {
  //   setLoading("Fetching Devices Data...")
  // },[])
  return (
    <LoadingIndicator.Provider value={{ loading, setLoading }}>
      <div>
        <BrowserRouter>
          <MainRouter />
        </BrowserRouter>
        <Toaster
          position="bottom-right"
          reverseOrder={false}
        />
      </div>
    </LoadingIndicator.Provider>
  );
}

export default App;
