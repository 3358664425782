import { useContext } from "react";
import { LoadingIndicator } from "../../context";
import loaderSvg from '../../assets/svg/loader.svg';
import { Image } from "react-bootstrap";

export default function MainLoader() {

    const { loading, setLoading }: any = useContext(LoadingIndicator);

    return (
        <>
            {loading && (
                <div className="loader">
                    <div className="text-center">
                        <Image src={loaderSvg} alt="loader" />
                        <div className="text-white h5">
                            Please Wait
                        </div>
                        <div className="fst-italic text-white fs-10 fw-bold ">
                            {loading}
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}