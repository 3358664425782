import moment from "moment";
import { useState } from "react";
import { Dropdown, Form, InputGroup, Table } from "react-bootstrap";
import { AiFillEdit, AiOutlineDelete } from "react-icons/ai";
import DeviceEditModal from "../Model/EditDevice.model";
import SkeletionLoader from "../Loader/Skeleton";
import TextHelper from "../../helper/text.helper";
import { HiEllipsisVertical, HiPencil, HiPencilSquare } from "react-icons/hi2";
import toast from "react-hot-toast";
import DevicesService from "../../services/public/devices.service";



interface ILiveUserTable {
  tableData: any,
}


const RegisteredDevicesTable = ({ tableData }: ILiveUserTable) => {

  const [edit, setEdit] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(0);

  const deleteDevice = async (id: string) => {
    await DevicesService.deleteDeviceById(id).then((res) => {
        if (res.status === 200) {
          toast.success("Device deleted successfully");
          window.location.reload();
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });

  }
  return <>
    <Table hover responsive striped bordered>
      <thead className="bg-secondary text-white">
        <tr className="fs-8 " >
          <th>Sr.No</th>
          <th>MAC ID</th>
          <th>Hardware Id</th>
          <th>Serial Number</th>
          <th>Purchase Date</th>

          <th>Screen Size</th>
          <th>Screen Resolution</th>
        
          <th>Screen Type</th>
          <th>Screen Refresh Rate</th>
          <th>Screen Touch</th>
          <th>Screen HDR</th>
          <th>Screen GSync</th>
          <th>Screen FreeSync</th>

          <th>Processor Make</th>
          <th>Processor</th>
          {/* <th>Processor Gen</th> */}
          <th>RAM</th>
          <th>RAM Type</th>
          <th>Storage Type</th>
          <th>Storage Size</th>

          <th>BIOS Type</th>
          <th>BIOS Version</th>
          <th>BIOS Date</th>

          <th>GPU Type</th>
          <th>GPU Make</th>
          <th>GPU Series</th>
          <th>GPU Model</th>
          <th>GPU VRAM</th>
          <th>GPU VRAM Type</th>
          <th>GPU VRAM Bus</th>
          <th>GPU VRAM Speed</th>


          <th>OS</th>
          <th>OS Version</th>
          <th>Last Checked On</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {tableData && tableData.length > 0 ? tableData.map((data: any, index: number) => (
          <tr key={index}>
            <td className="td-sno">
              {index + 1}
            </td>
            <td className="td-monospace fs-8">{(data && data.macId) || "--"}</td>
            <td className="td-monospace fs-8">{(data && data.hardwareId) || "--"}</td>
            <td className="td-monospace fs-8">{(data && data.serialNumber) || "--"}</td>
            <td className="td-date">{(data && moment(data.purchaseDate).format('DD-MM-YYYY')) || "--"}</td>

            <td className="fs-8">{(data && data.screenSize) || "--"}</td>
            <td className="fs-8">{(data && data.screenResolution) || "--"}</td>
            
            <td className="fs-8">{(data && data.screenType) || "--"}</td>
            <td className="fs-8">{(data && data.screenRefreshRate) || "--"}</td>
            <td className="fs-8">{(data && data.screenTouch) || "--"}</td>
            <td className="fs-8">{(data && data.screenHDR) || "--"}</td>
            <td className="fs-8">{(data && data.screenGSync) || "--"}</td>
            <td className="fs-8">{(data && data.screenFreeSync) || "--"}</td>

            <td className="fs-8">{(data && data.processorMake) || "--"}</td>
            <td className="fs-8">{(data && data.processorModel || data.processor_model) || "--"}</td>
            {/* <td className="fs-8">{(data && data.processor_gen) || "--"}</td> */}
            <td className="fs-8">{(data && data.ramSize || data.ram) || "--"}</td>
            <td className="fs-8">{(data && data.ramType) || "--"}</td>
            <td className="fs-8">{(data && data.storageType) || "--"}</td>
            <td className="fs-8">{(data && data.storageSize) || "--"}</td>

            <td className="fs-8">{(data && data.biosType) || "--"}</td>
            <td className="fs-8">{(data && data.biosVersion) || "--"}</td>
            <td className="fs-8">{(data && data.biosDate) || "--"}</td>

            <td className="fs-8">{(data && data.gpuType) || "--"}</td>
            <td className="fs-8">{(data && data.gpuMake) || "--"}</td>
            <td className="fs-8">{(data && data.gpuSeries) || "--"}</td>
            <td className="fs-8">{(data && data.gpuModel) || "--"}</td>
            <td className="fs-8">{(data && data.gpuVram) || "--"}</td>
            <td className="fs-8">{(data && data.gpuVramType) || "--"}</td>
            <td className="fs-8">{(data && data.gpuVramBus) || "--"}</td>
            <td className="fs-8">{(data && data.gpuVramSpeed) || "--"}</td>

            <td className="fs-8">{(data && TextHelper.capitalizeFirstLetter(data.os)) || "--"}</td>
            <td className="fs-8">{(data && data.osVersion || data.os_version) || "--"}</td>
            <td className="td-date">{(data && moment(data.last_checked_on).format('DD-MM-YYYY')) || "--"}</td>
            <td>
              <Dropdown>
                <Dropdown.Toggle as='span'
                  id="dropdown-custom"
                  className="custom-dropdown-toggle"
                  style={{ cursor: 'pointer' }}>
                  <HiEllipsisVertical/>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item className="d-flex align-items-center" onClick={() => { setEdit(true); setIndex(index); }}><HiPencilSquare/> <span className="ms-1">Edit</span></Dropdown.Item>
                 {/* delete  */}
                  <Dropdown.Item className="d-flex align-items-center" onClick={() => { deleteDevice(tableData[index]._id) }}><AiOutlineDelete /> <span className="ms-1">Delete</span></Dropdown.Item>
                  </Dropdown.Menu>
              </Dropdown>
            </td>
          </tr>
        )) : <SkeletionLoader rows={15} pattern={[
          { height: 25, width: 25 },
          { height: 25, width: 50 },
          { height: 25, width: 300 },
          { height: 25, width: 100 },
          { height: 25, width: 150 },
          { height: 25, width: 600 },
          { height: 25, width: 200 },
        ]} />}
      </tbody>
    </Table>
    {edit && <DeviceEditModal show={edit} setShow={setEdit} data={tableData[index]} id={tableData[index]._id} />}
  </>
}

export default RegisteredDevicesTable;