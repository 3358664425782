import moment from "moment";
import { useEffect, useState } from "react";
import { Form, InputGroup, Table } from "react-bootstrap";
import { AiFillEdit } from "react-icons/ai";
import VersionService from "../../services/public/nixexamVersion.service";
import EditLandscapeVersionModal from "../Model/EditLandscapeVersion";



interface ILiveUserTable {
    tableData: any,
}

const Landscape = ({ tableData }: ILiveUserTable) => {
  const [edit,setEdit]=useState<boolean>(false);
  const [index,setIndex]=useState<number>(0);
 
   return <>
     
   <Table hover responsive>
  <thead>
    <tr>
      <th>SR.No</th>
      <th>Version Date</th>
      <th>Last Commit Id</th>
       <th>Created</th>
       <th>Platform</th>
      <th>Hash</th>
      <th>Pyarmor version</th>
      <th>Pyarmor Key</th>
      <th>Node Version</th>
      <th>Actions</th>
      
    </tr>
  </thead>
  <tbody className="divide-y">
          {tableData && tableData.length > 0 ? tableData?.map((data: any, index: number) => {
            return (
              <tr className="table-light border border-gray-700 bg-white bg-dark" key={index}>
                <td className="nowrap font-weight-medium text-gray-900 text-white">
                  <span className="d-flex align-items-center text-dark">
                    {index + 1}
                  </span>
                </td>
                <td>{(data && moment(data.versionDate).format('YYYY/MM/DD'))|| '--'}</td>
                <td>{(data && data.last_commit_id) || '--'}</td>
                 <td>{(data && data.created_by)|| '--'}</td>
                <td>{(data && data.platform)|| '--'}</td> 
                <td>{(data && data.hash)|| '--'}</td>
                <td>{(data && data.pyarmor_version)|| '--'}</td>
                 <td>{(data && data.pyarmor_key)|| '--'}</td>
                <td>{(data && data.node_version)|| '--'}</td>
                 <td>
                  <AiFillEdit onClick={()=>{setEdit(true); setIndex(index)}}/>
                </td>
              </tr>
            )
          }) : <tr><td  colSpan={20}>No data found</td></tr>}
        </tbody>
</Table>
      {edit && <EditLandscapeVersionModal show={edit} setShow={setEdit} data={tableData[index]} id={tableData[index]._id}/>}

</>
}

export default Landscape;