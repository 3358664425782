import axios from "axios";

export default async function makeUploadRequest(url: any, method: any, formData: any) {
    let requestConfig = {
        baseURL: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_VERSION}`,
        url: url,
        method: method,
        headers: {
            Authorization: localStorage.getItem("authkey-super") || "",
            "Content-Type": "multipart/form-data",
        },
        data: formData
    };

    return await axios.request(requestConfig);
}
