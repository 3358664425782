import { useContext, useEffect, useState } from 'react';
import { Button, Card, Dropdown, Form, InputGroup } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { HiBars3BottomRight, HiCog8Tooth, HiDeviceTablet, HiPlusCircle } from 'react-icons/hi2';
import AddDeviceModal from '../../../components/Model/AddDevice.model';
import RegisteredDevicesTable from '../../../components/Table/registeredDevices.table';
import DevicesService from '../../../services/public/devices.service';
import { LoadingIndicator } from '../../../context';

const Devices = () => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchData, setSearchData] = useState<any[]>([]);
  const [show, setShow] = useState<boolean>(false);

  const { loading, setLoading }: any = useContext(LoadingIndicator);

  const filteredData = async () => {
    setLoading("Fetching Devices Data...")
    await DevicesService.all(searchTerm).then((res) => {
      setLoading(undefined)
      if (res.status === 200) {
        setSearchData(res.data);
      }
    }).catch((err) => {
      setLoading(undefined)
      toast.error('Error while fetching devices:', err);
    });

  }



  useEffect(() => {
    filteredData();;
  }, [searchTerm])



  return (
    <>
      <Card className="mx-5 mt-4 cdn-card shadow cdn-card-padding">
        <div>
          <div className='d-flex justify-content-between align-items-center'>
            <h5 className='fw-bold'>
              Registered Devices
            </h5>
            <div className='d-flex justify-content-between align-items-center'>

              <InputGroup>
                <Form.Control
                  type="text"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </InputGroup>
              <Dropdown align="end"
>
            <Dropdown.Toggle
              id="dropdown-custom"
              style={{ cursor: 'pointer' }}
              variant='outline-secondary'
              size='sm'
              className='ms-2'
            >
             <HiBars3BottomRight/>
            </Dropdown.Toggle>
            <Dropdown.Menu className='fs-8'>
              <Dropdown.Item onClick={() => { setShow(true) }} > <HiDeviceTablet/> <span className='ms-2'> Add Device</span></Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
            </div>
          </div>

        </div>
        <div className='d-flex justify-content-end align-items-center'>
        
        </div>
        <Card.Body>
          <RegisteredDevicesTable tableData={searchData} />
        </Card.Body>
      </Card>
      {show && <AddDeviceModal show={show} setShow={setShow} />}
    </>
  )
}

export default Devices;