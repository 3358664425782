import Skeleton from 'react-loading-skeleton'

interface IPattern {
    height: number
    width: number
}

interface ISkeletonLoader {
    rows: number
    pattern: IPattern[]
}

export default function SkeletionLoader(props: ISkeletonLoader) {
    return (
        <>
            {Array(props.rows).fill(0).map((item, index) => {
                return (
                    <div className='d-flex justify-content-between align-items-center'>
                        {props.pattern.map((item, index) => {
                            return (
                                <Skeleton
                                    key={index}
                                    height={item.height}
                                    width={item.width}
                                    className='m-1'
                                />
                            )
                        })}
                    </div>
                )
            })}

        </>
    )
}