import makeRequest, { RequestMethod } from "../make.request";
import urls from "../urls";

export default class CdnService {
    static async create(payload: any) {
        return await makeRequest(urls.private.cdnApp.create, RequestMethod.POST, payload)
    }

    static async get() {
        return await makeRequest(urls.private.cdnApp.get, RequestMethod.GET)
    }

    static async deleteCdnApp(cdnId: any) {
        return await makeRequest(urls.private.cdnApp.delete + "/" + cdnId, RequestMethod.DELETE)
    }

    static async getCdnAppById(cdnId: any) {
        return await makeRequest(urls.private.cdnApp.cdnAppById + "/" + cdnId, RequestMethod.GET)
    }

    static async editCdnApp(cdnId: any, payload: any) {
        return await makeRequest(urls.private.cdnApp.update + "/" + cdnId, RequestMethod.PUT, payload)
    }
}