import { useEffect, useState } from "react";
import { FaNetworkWired } from "react-icons/fa";
import { useNavigate } from "react-router";
import Auth from "../../Auth";
import { Row, Col, Form, Button, Card, Container } from 'react-bootstrap';
import { toast } from "react-hot-toast";
import AuthService from "../../services/public/auth.service";

export default function LoginIndex() {

  const [username, setUsername] = useState<string>();
  const [password, setPassword] = useState<string>();

  const onLogin = async (event: any) => {
    event.preventDefault();
    console.log(username, password, 'data1')
    if (!username || !password) {
      toast.error("Required fields")
      return false
    } else if (username && password) {
      await AuthService.login(username, password).then((res) => {
        if (res.status === 200) {
          toast.success("Login Success")
          Auth.setToken(res.data.token);
          window.location.href = "/admin/dashboard"
        }
      }).catch(err => {
        console.log(err, 'err')
        toast.error(err.response.data.message)
      })
    }

  }


  return (
    <>
      <Container>
        <Row className="mt-4">
          <Col md={3}>
          </Col>
          <Col md={6}>
            <Card className="cdn-card cdn-shadow">
              <Card.Body>
                <div>
                  <div className="d-flex justify-content-center align-items-center">
                    <div className="text-center">
                      <FaNetworkWired className="text-muted h1" />
                      <h1 className="mt-3 h1 fw-bold text-secondary">EXMCDN</h1>
                      <h6 className="mt-3  fw-bold text-secondary">Enixm</h6>
                      <p className="text-muted">Login to your account</p>
                    </div>
                  </div>
                  <div>
                    <Row className="mb-3">
                      <Col>
                        <Form.Label htmlFor="email-address" className="">
                          Email address
                        </Form.Label>
                        <Form.Control
                          id="email-address"
                          name="username"
                          type="email"
                          autoComplete="email"
                          required
                          placeholder="Email address"
                          onChange={(e) => setUsername(e.target.value)}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col>
                        <Form.Label htmlFor="password">
                          Password
                        </Form.Label>
                        <Form.Control
                          id="password"
                          name="password"
                          type="password"
                          autoComplete="current-password"
                          required
                          placeholder="Password"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col>
                        <Button
                          type="submit"
                          variant="secondary"
                          className="w-100"
                          onClick={onLogin}
                        >
                          Sign in
                        </Button></Col>
                    </Row>
                  </div>
                </div>

              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
          </Col>
        </Row>
      </Container>
    </>
  );
}